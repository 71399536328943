import { Store } from '@/store'
import { useEffect } from 'react'

function updateSubscriptionStatus(isSubscriptionActive: boolean) {
	Store.setState(draft => {
		draft.isSubscriptionActive = isSubscriptionActive
	})
}

function updateCameraPermissionGranted(isCameraPermissionGranted: boolean) {
	Store.setState(draft => {
		draft.isCameraPermissionGranted = isCameraPermissionGranted
	})
}

function getGlobalStore() {
	return Store.getState()
}

/**
 * Mounts functions to global window object
 */
export const useWebviewMessageHandler = () => {
	useEffect(() => {
		window.updateSubscriptionStatus = updateSubscriptionStatus
		window.getGlobalStore = getGlobalStore
		window.updateCameraPermissionGranted = updateCameraPermissionGranted
	}, [])
}
