import { DEFAULT_MESSAGE_COUNT } from '@/env-vars'
import { Store } from '@/store'
import { useEffect } from 'react'

// Function to check if two timestamps are on the same day
const isSameDay = (date1: number, date2: number): boolean => {
	const day1 = new Date(date1).setHours(0, 0, 0, 0)
	const day2 = new Date(date2).setHours(0, 0, 0, 0)
	return day1 === day2
}

export const decrementMessagesLeft = () => {
	Store.setState(draft => {
		draft.messageQuota.count = draft.messageQuota.count - 1
	})
}

/**
 * Implement MessageLeft Quota which resets on daily basis
 */
export const useMessageQuota = () => {
	const { count, lastMessageDate } = Store.useBoundStore(s => s.messageQuota)

	useEffect(() => {
		// on mount reset quota
		if (isSameDay(lastMessageDate, Date.now()) === false) {
			Store.setState(draft => {
				draft.messageQuota.count = DEFAULT_MESSAGE_COUNT
				draft.messageQuota.lastMessageDate = Date.now()
			})
		}
	}, [])

	// optimistically show default message count if last request was made on previous day
	const messagesLeft = isSameDay(lastMessageDate, Date.now()) ? count : DEFAULT_MESSAGE_COUNT

	return { messagesLeft }
}
