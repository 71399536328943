const routeToPaywall = () => {
	window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'PAYWALL_ROUTE' }))
}

const openCameraAccessDialog = () => {
	window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'OPEN_CAMERA_ACCESS_DIALOG' }))
}

export const jsBridge = {
	routeToPaywall,
	openCameraAccessDialog,
}
