import type { Chat, ExtendedChatMessage, VideoRecommendationsMessage, YoutubeVideoDetails } from './types'
import { devtools, persist } from 'zustand/middleware'
import { create } from 'zustand'
import { produce, WritableDraft } from 'immer'
import { DEFAULT_MESSAGE_COUNT } from '@/env-vars'

type GlobalState = {
	isSubscriptionActive: boolean
	isCameraPermissionGranted: boolean
	chatInputText: string
	chatId?: string
	chats: Record<string, Chat>
	// `referenceVideoLink` is used to scroll to the video message in a chat if it exists.
	referenceVideoLink?: string
	referenceMessageContent?: string
	isSidebarOpen: boolean
	alertDialog?: {
		title: string
		description: string
		onConfirm: () => void
	}
	currentCategories: {
		isDialogOpen: boolean
		selectedCategory?: string
	}
	imageCropView: {
		isDialogOpen: boolean
		imageDataUrl?: string
	}
	videoRecommendations?: {
		currentVideo: YoutubeVideoDetails
		currentMessage: VideoRecommendationsMessage
	}
	messageQuota: {
		count: number
		lastMessageDate: number
	}
}

const initialGlobalState: GlobalState = {
	// optimistic behavior by setting to true
	isSubscriptionActive: true,
	isCameraPermissionGranted: false,
	chatInputText: '',
	chats: {},
	isSidebarOpen: false,
	currentCategories: {
		isDialogOpen: false,
	},
	imageCropView: {
		isDialogOpen: false,
	},
	messageQuota: {
		count: DEFAULT_MESSAGE_COUNT,
		lastMessageDate: Date.now(),
	},
}

const useBoundStore = create<GlobalState>()(
	devtools(
		persist(
			(set, get) => ({
				...initialGlobalState,
			}),
			{
				name: 'global-storage',
				partialize: (state) => ({
					messageQuota: state.messageQuota,
					// add values which needs to persisted to storage
				}),
			},
		),
	),
)

// For Generic State Updates
const setState = (callback: (draft: WritableDraft<GlobalState>) => void) => {
	useBoundStore.setState((state) =>
		produce(state, (draft) => {
			callback(draft)
		})
	)
}

// Define more complex state changes as follows:

/**
 * Message Cache is important as we usually do not fetch messages for the chats
 * We lazily fetch messages for the chats when the user opens the chat, then cache those
 * messages, the messageCache flag is used to indicate if the messages were also fetched
 */
const updateChats = ({ newChats, updateMessages }: { newChats: Chat[]; updateMessages: boolean }) => {
	useBoundStore.setState((state) =>
		produce(state, (draft) => {
			newChats.map((newChat) => {
				const oldMessages = state.chats[newChat.chatId]?.messages ?? []
				draft.chats[newChat.chatId] = {
					...newChat,
					// if updateMessages set to true, use the message coming from server, else retain the old data
					messages: updateMessages ? newChat.messages : oldMessages,
					// Update the time if the messages are being updated
					messagesCachedAt: updateMessages ? new Date() : undefined,
				}
			})
		})
	)
}

/**
 * Automatically handles Chat Creation and Message Appending
 */
const appendMessagesToChat = ({ chatId, messages }: { chatId: string; messages: ExtendedChatMessage[] }) => {
	setState((draft) => {
		if (draft.chats[chatId]) {
			draft.chats[chatId].messages = draft.chats[chatId].messages.concat(messages)
			draft.chats[chatId].lastUpdatedAt = new Date().toISOString()
		} else {
			draft.chats[chatId] = {
				chatId,
				messages,
				createdAt: new Date().toISOString(),
				lastUpdatedAt: new Date().toISOString(),
			}
		}
	})
}

/**
 * Appends the content to the existing content of the last message in the chat
 */
const updateChatMessage = (
	{ chatId, content, messageIdx }: { chatId: string; content: string; messageIdx: number },
) => {
	setState((draft) => {
		const chat = draft.chats[chatId]
		const lastMessage = chat?.messages?.at(messageIdx)
		if (!lastMessage) {
			console.debug('Error Appending. No Messages in Chat')
			return
		}
		lastMessage.content += content
	})
}

const isPaywallActive = () => {
	return window.isReactNativeApp && !Store.getState().isSubscriptionActive
}

/**
 * Unified Store for the application
 */
export const Store = {
	useBoundStore,
	setState,
	getState: useBoundStore.getState,
	isPaywallActive,
	updateChats,
	appendMessagesToChat,
	updateChatMessage,
}
